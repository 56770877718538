var exports = {};
exports = {
  A: {
    D: {
      "1": "LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "2": "I r J D E F A B C K L G",
      "33": "0 1 2 3 4 5 6 7 8 9 M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB"
    },
    L: {
      "1": "H"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "2": "C K L G M N O"
    },
    C: {
      "1": "NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "7B qB I r J D E F 8B 9B",
      "33": "0 1 2 3 4 5 6 7 8 9 A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB"
    },
    M: {
      "1": "c"
    },
    A: {
      "2": "J D E F A B 6B"
    },
    F: {
      "1": "8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "2": "F B C LC MC NC OC nB 4B PC oB",
      "33": "0 1 2 3 4 5 6 7 G M N O s t u v w x y z"
    },
    K: {
      "1": "d",
      "2": "A B C nB 4B oB"
    },
    E: {
      "1": "B C K L G nB oB yB HC IC zB 0B 1B 2B pB 3B JC",
      "2": "I r CC wB DC KC",
      "33": "J D E F A EC FC GC xB"
    },
    G: {
      "1": "ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "2": "wB QC 5B RC",
      "33": "E SC TC UC VC WC XC YC"
    },
    P: {
      "1": "sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C",
      "2": "I"
    },
    I: {
      "1": "H",
      "2": "qB I lC mC nC oC 5B pC qC"
    }
  },
  B: 6,
  C: "isolate from unicode-bidi"
};
export default exports;